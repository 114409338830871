import { makeStyles, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { SyntheticEvent, useState } from 'react';

import styles from './notificationItem.styles';

const useStyles = makeStyles(styles);

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const NotificationItem = ({ children, type, isOpen = false }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      className={classes.notification}
    >
      <Alert onClose={handleClose} severity={type}>
        {children}
      </Alert>
    </Snackbar>
  );
};
