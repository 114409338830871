import { createContext } from 'react';

import { IAuthContext } from './auth.types';

export const AuthContext = createContext<IAuthContext>({
  user: {
    loggedIn: false,
    email: null,
    uid: null,
    role: null,
    companyId: null,
    isBlocked: false
  }
});

AuthContext.displayName = 'AuthContext';
