import { auth } from '@config/firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email: string, password: string) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string) =>
  auth.signInWithEmailAndPassword(email, password);

// Sign in with custom token
export const doSignInWithCustomToken = (token: string) => auth.signInWithCustomToken(token);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = (email: string) => auth.sendPasswordResetEmail(email);

// Verify Password Reset Code
export const verifyPasswordResetCode = (code: string) => auth.verifyPasswordResetCode(code);

// Cponfirm Password Reset
export const confirmPasswordReset = (code: string, password: string) => auth.confirmPasswordReset(code, password);

// Password Change
export const doPasswordUpdate = async (password: string) => {
  if (auth.currentUser) {
    await auth.currentUser.updatePassword(password);
  }
  throw Error('No auth.currentUser!');
};

// Auth State Change
export const onAuthStateChange = (callback, setLoading) => {
  return auth.onAuthStateChanged(async (user) => {
    if (user) {
      const result = await user.getIdTokenResult();
      const { claims } = result;

      callback({
        loggedIn: true,
        email: user.email,
        uid: user.uid,
        role: claims.role,
        companyId: claims.companyId,
        isBlocked: claims.blocked
      });
    } else {
      callback({ loggedIn: false });
    }
    setLoading(false);
  });
};
