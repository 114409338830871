import { createMuiTheme, Theme } from '@material-ui/core/styles';

import { mantis, white } from './variables';

export const theme: Theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins'
  },
  palette: {
    primary: {
      main: white
    },
    secondary: {
      main: mantis
    }
  }
});
