import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from '../en/translation';
import fr from '../fr/translation';

i18n.use(LanguageDetector).init({
  resources: {
    en: en,
    fr: fr
  },
  fallbackLng: 'en',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    wait: true
  }
});

export default i18n;
