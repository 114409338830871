import 'firebase/auth';
import 'firebase/functions';

import * as firebase from 'firebase/app';
const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID
};

let app: firebase.app.App;
if (!firebase.apps.length || !app) {
  app = firebase.initializeApp(config);
}

export const auth = firebase.auth();
export const functions = app.functions('europe-west3');
