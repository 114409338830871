export const black = '#1D2438';
export const white = '#ffffff';
export const red = '#f44336';
export const athensGray = '#EFF0F3';
export const mantis = '#6CC164';
export const stack = '#9B9F9A';
export const blackHaze = '#F4F6F6';
export const gallery = '#F0F0F0';
export const mirage = '#1D2438';
export const botticelli = '#D9DEEB';
export const persimmon = '#FF5C5C';
export const mercury = '#e8e8e8';
export const bombay = '#AEB1B9';
export const morning = '#8CD9D9';
export const cherokee = '#FBDB93';
export const monaLisa = '#feae9a';

export const inputBorderRadius = 8;
export const buttonBorderRadius = 85;
export const checkboxBorderRadius = 8;
export const cardBorderRadius = 16;
export const dialogBorderRadius = 32;

export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;

export const basicShadow = '0px 10px 24px rgba(0, 0, 0, 0.05)';
export const primaryShadow = '2px 6px 6px -3px rgba(108, 193, 100, 0.3)';
export const secondaryShadow = '2px 6px 6px -3px rgba(255, 92, 92, 0.3)';
