import { Box } from '@material-ui/core';
import React from 'react';
import { createPortal } from 'react-dom';

import { NotificationItem } from '../notificationItem/notificationItem';

export const NotificationContainer = ({ notifications }) => {
  return createPortal(
    <Box position="fixed" top="15px" right="15px" left="auto" zIndex={1400}>
      {notifications.map((item) => (
        <NotificationItem key={item.id} type={item.type} isOpen={item.isOpen}>
          {item.content}
        </NotificationItem>
      ))}
    </Box>,
    document.body
  );
};
