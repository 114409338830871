const en = {
  translations: {
    register: {
      title: 'Get started by applying for a Bioledger account',
      logIn: 'Already registered? ',
      logInLink: 'Log in',
      policy:
        'By proceeding you agree to <1>Terms and conditions</1>, <3>Acceptable Use Policy</3> and <5>Privacy Policy</5>'
    },
    login: {
      title: 'Welcome back',
      // eslint-disable-next-line quotes
      register: "Don't have account yet? ",
      registerLink: 'Register',
      forgotPassword: 'I forgot my password, ',
      recoveryLink: 'please send me a recovery email',
      loggedIn: 'You have successfully logged in',
      blockedAccount: 'Your account has been blocked. Please contact the administrator admin@bioledger.io'
    },
    resetPassword: {
      title: 'Forgot password'
    },
    newPassword: {
      title: 'Set new password'
    },
    pages: {
      notFound: {
        button: {
          back: 'Back to homepage'
        }
      },
      superAdmin: {
        statistics: {
          clientsRegistered: 'Clients registered',
          accountsCreated: 'Accounts created',
          totalVolume: 'Total Volume transfered this month',
          profit: 'Earned this month'
        },
        clientDetails: {
          viewAccount: 'View Account',
          disableAccount: 'Block Account',
          enableAccount: 'Unblock Account',
          deleteAccount: 'Delete Account',
          removedLabel: 'This company is removed',
          clientsList: 'Clients List',
          remove: {
            title: 'Config remove',
            description: 'You are about to remove this company.',
            accept: 'Remove',
            decline: 'Cancel'
          },
          detailsCard: {
            title: 'Details',
            administrator: 'Administrator',
            address: 'address',
            registrationId: 'Company Registration ID',
            numberOfAccounts: 'Number of Accounts',
            wateNumber: 'Wate carrier license number',
            voluntaryCertificate: 'Voluntary scheme certificate',
            animalStatus: 'Animal by product status'
          }
        },
        assignmentDetails: {
          goBack: 'Go back to client details'
        },
        adminPaymentsList: {
          title: {
            overduePayments: 'Overdue payments',
            recentPayments: 'Recent payments received'
          },
          links: {
            overdue: 'See all overdue payments',
            received: 'See all payments'
          },
          emptyList: 'No payments yet',
          failedDate: 'Due date:'
        }
      },
      receiptDetails: {
        downloadReceipt: 'Download receipt',
        generateInvoice: 'Generate invoice',
        sections: {
          summary: {
            title: 'Receipt summary',
            certificationType: 'Certification type',
            volumeReceived: 'Volume received',
            price: 'Price',
            certifiedTitle: 'Certified volume received',
            uncertifiedTitle: 'Uncertified volume received',
            bioledgerTitle: 'Bioledger volume received',
            total: 'Total'
          },
          paymentMethod: {
            title: 'Payment method',
            paymentForm: 'Payment form',
            paid: 'Paid',
            default: 'default',
            expiration: 'Expiration'
          },
          date: {
            title: 'Date',
            issuedOn: 'Issued on',
            dueDate: 'Due date'
          }
        }
      },
      calendar: {
        title: 'Calendar',
        empty: {
          title: 'Your calendar is empty',
          description_line_1: 'There is nothing to show yet...',
          description_line_2: 'Add your first assignment to fill in your calendar'
        },
        driver: 'Driver',
        customer: 'Customer',
        label: {
          assignment: 'Assignment ID',
          collection: 'Collection ID',
          sales: 'Sales ID'
        },
        status: {
          ongoing: 'Ongoing',
          scheduled: 'Scheduled',
          completed: 'Completed',
          incomplete: 'Incomplete'
        }
      },
      sales: {
        title: 'Sales'
      },
      dashboard: {
        title: 'Dashboard',
        description:
          'Happy to have you here! You can now access Bio Ledger features including building routes and tracking drivers progress.',
        incompletedSettings: {
          title: 'Complete account settings',
          description:
            'Add details to your profile and upload company’s logo so that your drivers will see it in the app',
          buttonLabels: {
            add: 'Complete settings'
          }
        },
        currentBalance: {
          title: 'Current Balance',
          tabs: {
            yesterday: 'Yesterday',
            today: 'Today'
          },
          totalVolume: 'Total Volume',
          litres: 'Litres',
          kilograms: 'Kilograms',
          labels: {
            certified: 'Red compliant',
            bioledger: 'Bioledger',
            uncertified: 'Uncertified',
            received: 'received',
            sent: 'sent'
          }
        },
        todayCollections: {
          title: "Today's Collections",
          link: 'See all collections',
          labels: {
            planned: 'Planned',
            completed: 'Completed',
            ongoing: 'Ongoing'
          }
        },
        todaySales: {
          title: "Today's Sales",
          link: 'See all sales orders',
          labels: {
            scheduled: 'Scheduled',
            completed: 'Completed',
            ordersAmount: '{{amount}} orders',
            orderAmount: '{{amount}} order'
          }
        },
        recentDeliveries: {
          title: 'Recent deliveries',
          link: 'See all deliveries'
        },
        volumeDetails: {
          title: 'Volume Details',
          link: 'See stock details',
          tabs: {
            litres: 'Litres',
            kilograms: 'Kilograms'
          },
          totalVolume: 'Total Volume',
          litres: 'Litres',
          labels: {
            certified: 'Red compliant',
            bioledger: 'bioledger',
            uncertified: 'Uncertified'
          }
        },
        incompletedDrivers: {
          title: 'Add drivers',
          description: 'Import drivers or add them one by one',
          buttonLabels: {
            add: 'Add driver'
          }
        },
        incompletedLocations: {
          title: 'Add locations',
          description: 'Add locations to start creating routes',
          buttonLabels: {
            add: 'Add location'
          }
        },
        total: {
          title: 'Total volume',
          stats: {
            ucoVeg: 'UCO Veg',
            ucoVegAnimal: 'UCO Veg + Animal',
            foodWaste: 'Food waste',
            brownGrease: 'Brown grease'
          }
        },
        collections: {
          title: 'Today collections',
          link: 'See tracking'
        },
        alerts: {
          title: 'Latest alerts',
          link: 'See all alerts',
          emptyList: 'No alarms yet',
          descriptions: {
            completedAssignment: 'completed assignment',
            reportedProblem: 'reported a problem',
            madeCollection: 'made collection'
          }
        }
      },
      settings: {
        title: 'Settings',
        general: {
          title: 'Security',
          description: 'View and update your\naccount password'
        },
        account: {
          title: 'Account Settings',
          description: 'View your account details\nand manage your staff'
        },
        billing: {
          title: 'Billing',
          description: 'Manage you billing information\nand manage invoices'
        }
      },
      inviteAdmin: {
        title: 'Add staff',
        link: 'Account settings'
      },
      adminPermissions: {
        description: 'Permissions'
      },
      generalSettings: {
        title: 'Security'
      },
      billingSettings: {
        title: 'Billing Settings'
      },
      drivers: {
        title: 'Drivers',
        noDrivers: {
          title: 'No drivers yet',
          description: 'Start adding drivers to assign them to the routes'
        },
        buttonLabels: {
          addDriver: 'Add driver'
        },
        popup: {
          title: 'Upload drivers',
          description: 'Do you really want to upload the file:',
          buttonLabels: {
            confirm: 'Upload drivers'
          }
        }
      },
      createDriver: {
        title: 'Add Driver',
        link: 'Drivers'
      },
      editDriver: {
        title: 'Edit Driver',
        link: 'Drivers'
      },
      locations: {
        title: 'Locations',
        collections: {
          emptyList: 'No collections found'
        },
        noLocations: {
          title: 'No locations yet',
          description: 'Start adding locations to assign them to the routes'
        },
        buttonLabels: {
          addLocation: 'Add new location'
        },
        popup: {
          title: 'Upload locations',
          description: 'Do you really want to upload the file:',
          buttonLabels: {
            confirm: 'Upload locations'
          }
        }
      },
      createLocation: {
        title: 'Add Location Manually',
        link: 'Locations'
      },
      editLocation: {
        title: 'Edit Location',
        link: 'Locations'
      },
      locationDetails: {
        assignToRoute: 'Assign to a Route',
        routesAssigned: 'Routes assigned',
        scheduledAssignments: 'Scheduled assignments',
        collectionsCompleted: 'Collections completed',
        details: 'Details',
        address: 'Address',
        manager: 'Manager',
        voluntarySchemeCertificateNumber: 'Voluntary scheme certificate number',
        environmentalNumber: 'Environmental number',
        wasteCarrierLicenseNumber: 'Waste carrier license number',
        abpPermitNumber: 'ABP permit number',
        expiryDate: 'Expiry date:',
        competentAuthority: 'Competent authority:',
        edit: 'Edit'
      },
      driverDetails: {
        noAssignmentsToday: 'No assignments scheduled for today',
        assignmentScheduledForToday: 'Assignments scheduled for today',
        scheduledAssignments: 'Scheduled assignments',
        assignmentsCompleted: 'Assignments completed',
        problemsReported: 'Problems reported',
        emptyList: 'No assignments found',
        timeline: 'Timeline',
        details: 'Details',
        address: 'Address',
        vehicleRegistrationPlate: 'Vehicle registration plate',
        wasteCarrierLicenseNumber: 'Waste carrier license number',
        administrator: 'Administrator',
        edit: 'Edit',
        seeLiveTracking: 'See live tracking'
      },
      routes: {
        title: 'Routes',
        popup: {
          title: 'Upload routes',
          description: 'Do you really want to upload the file:',
          buttonLabels: {
            confirm: 'Upload routes'
          }
        },
        noRoutes: {
          title: 'No routes yet',
          description: 'Create routes for your drivers.'
        },
        buttonLabels: {
          addRoute: 'Add route'
        }
      },
      createRoute: {
        title: 'Add route',
        link: 'Routes'
      },
      editRoute: {
        title: 'Edit Route',
        link: 'Routes'
      },
      routeDetails: {
        edit: 'Edit',
        duplicateRoute: 'Duplicate route'
      },
      assignments: {
        title: 'Assignments',
        noAssignments: {
          title: 'No assignments yet',
          description: 'Create assignment.'
        },
        buttonLabels: {
          addAssignment: 'Create assignment'
        }
      },
      createAssignment: {
        title: 'Create new assignment',
        link: 'Assignments'
      },
      assignmentDetails: {
        title: 'Assignment summary',
        noSummaryAvailable: 'No summary available yet',
        emptyWeight: 'Empty weight',
        weightSubmitted: 'Weight submitted',
        total: 'Total',
        details: 'Details',
        driver: 'Driver',
        route: 'Route',
        edit: 'Edit',
        link: 'Assignments'
      },
      editAssignment: {
        title: 'Edit assignment',
        link: 'Assignments'
      },
      collections: {
        title: 'Collections',
        noCollections: {
          title: 'No collections yet',
          description: 'Nothing was collected yet.',
          links: '<0>See ongoing Assignments</0> or <2>create new assignment</2>'
        },
        buttonLabels: {
          addCollection: 'Add new collection'
        }
      },
      proposedCollectionDetails: {
        link: 'Proposed collections',
        collectionInformation: 'Collection information',
        pleaseSelectProductType: 'Please select a product type you want to transform this collection into:',
        additionalDataRequired: 'Additional Data Required'
      },
      collectionDetails: {
        link: 'Collections',
        details: 'Details',
        location: 'Location (Current Holder)',
        driver: 'Driver (Collector)',
        positionTooFar: 'Detected location of this collection is: ',
        collectionSummary: 'Collection summary',
        volume: 'Volume/Weight',
        wasteType: 'Waste Type',
        packaging: 'Packaging',
        payment: 'Payment',
        paymentFrom: 'Payment from',
        paid: 'Paid',
        documents: 'Documents',
        photos: 'Photos',
        reportedProblem: 'Reported problem',
        print: 'Print Waste Transfer Note for this collection'
      },
      createCollection: {
        title: 'New Collection',
        link: 'Collections'
      },
      stock: {
        title: 'Stock',
        noSales: {
          title: 'No stock yet',
          description: 'Nothing was collected yet.'
        },
        stats: {
          total: 'Total',
          finalProducts: 'Final products',
          rawMaterials: 'Raw materials',
          kgReservedForSale: 'kg reserved for sale'
        },
        buttonLabels: {
          createSalesOrder: 'Create sales order',
          assignToSalesOrder: 'Assign to Sales order',
          adjustStock: 'Adjust stock'
        }
      },
      adjustStock: {
        title: 'Adjust stock',
        link: 'Stock'
      },
      orders: {
        title: 'Sales',
        noOrders: {
          title: 'No orders yet',
          description: 'Nothing was ordered yet.'
        },
        buttonLabels: {
          createSalesOrder: 'Create sales order'
        }
      },
      createOrder: {
        title: 'New sales order',
        link: 'Sales'
      },
      createSale: {
        title: 'New sales order',
        link: 'Sales'
      },
      orderDetails: {
        link: 'Sales',
        customer: 'Customer (Collector)',
        environmentalPermitNumber: 'Environmental Permit Number',
        wasteCarrierLicenseNumber: 'Waste Carrier License Number',
        manager: 'Manager',
        address: 'Address',
        vsc: 'Voluntary Scheme Certificate',
        vscExpiryDate: 'Expiry date:',
        abpPermitNumber: 'Abp Permit Number',
        abpCompetentAuthority: 'Competent Authority:',
        abpExpiryDate: 'Expiry date:',
        seller: 'Seller',
        email: 'E-mail',
        phone: 'Phone number',
        print: 'Print Waste Transfer Note for this collection',
        accept: 'Approve',
        reject: 'Reject',
        acceptModalTitle: 'Approve offer',
        acceptModalDescription: `Are you sure you want to accept this collection? 
        You won’t be able to reverse this action.`,
        rejectModalTitle: 'Reject offer',
        rejectModalDescription: `Are you sure you want to reject this collection? 
        You won’t be able to reverse this action.`,
        modalClose: 'Close'
      }
    },
    menu: {
      clients: 'Clients',
      payments: 'Payments',
      dashboard: 'Dashboard',
      calendar: 'Calendar',
      drivers: 'Drivers',
      locations: 'Locations',
      routes: 'Routes',
      collections: 'Collections',
      assignments: 'Assignments',
      tracking: 'Live Tracking',
      settings: 'Settings',
      stock: 'Stock',
      orders: 'Sales'
    },
    form: {
      collection: {
        sections: {
          details: {
            recipient: 'Recipient',
            collectionDetails: 'Collection details',
            admin: 'Admin',
            volountarySchemeCertificate: 'Voluntary scheme certificate',
            environmentalPermitNumber: 'Environemntal permit number',
            wasteCarrierLicenseNumber: 'Waste carrier license number',
            abpPermitNumber: 'ABP permit number',
            competentAuthority: 'Competent authority',
            expiryDate: 'Expiry date',
            productType: 'Choose product type',
            rawMaterialType: 'Raw material type',
            totalGHGEmissionsFromSupplyAndUseOfTheFuel: 'Total GHG emissions from supply and use of the fuel'
          },
          collectionDetails: {
            collectionIdNumber: 'Collection ID Number',
            assignmentIdNumber: 'Assignment ID Number (optional)',
            dateOfDispatchFormat: 'Date of dispatch format',
            addressOfDispatch: `Address of dispatch/shipping of the sustainable material - if different from the
            Supplier's certification address.`,
            addressOfReceipt: `Address of receipt/receiving point of the sustainable material - if different from the
            Recipient's certification address.`,
            collectionDate: 'Date of dispatch',
            productType: 'Product type',
            rawMaterialType: 'Raw material type',
            payment: 'Payment',
            note: 'Note',
            cash: 'cash',
            account: 'account',
            credit: 'credit',
            documents: 'documents',
            uploadADocument: 'Upload a document'
          },
          feedstockDetails: {
            collectionQuantity: 'Collection quantity',
            energyContent: 'Energy content (optional)',
            packagingType: 'Packaging type',
            amount: 'Amount',
            addAnother: 'Add another',
            countryOfOrigin: 'Country of origin',
            percentage: 'Percentage',
            additionalCertification: 'Additional certification (optional)',
            sustainabilityCriteriaOfTheBiomass: 'Sustainability criteria of the biomass',
            sustainabilityCriteriaOfTheBiomassText:
              'The raw material complies with the relevant sustainability criteria according to Art. 29 (2) - (7) RED II',
            sustainabilityCriteriaOfTheBiomassAgricultural:
              'The agricultural biomass was cultivated as an intermediate crop (if applicable)',
            sustainabilityCriteriaOfTheBiomassAgricultural2:
              'The agricultural biomass additionally fulfills the measures for low ILUC risk feedstocks (if applicable)',
            sustainabilityCriteriaOfTheBiomassText2:
              'Raw material meets the definition of waste or residue according to the RED II i.e. it was not intentionally produced, modified, contaminated or discarded to meet the definition of waste or residue.',
            sustainabilityCriteriaOfTheBiomassText2Short:
              'Raw material meets the definition of waste or residue according to the RED II',
            sustainabilityCriteriaOfTheBiomassN20: 'Disaggregated Default Value for Soil N2O emissions is applied',
            sustainabilityCriteriaOfOilExtraction: 'Disaggregated Default Value for oil extraction only applied',
            sustainabilityCriteriaOfTheBiomassEsca:
              'Esca: Bonus of 45g CO2eq/MJ manure in the case animal manure is used as a substrate for the production of biogas and biomethane',
            sustainabilityCriteriaOfTheBiomassEI:
              'EI: Bonus of 29g CO2eq/MJ biofuel/bioliquid/biomass fuel if biomass is obtained from restored degraded land',
            sustainabilityCriteriaOfTheBiomassIncentives:
              'For biogas supply chains: Were incentives/subsidies received for the production of the biogas? ',
            sustainabilityCriteriaOfTheBiomassInstallation:
              'The installation where the final biofuel producer or biomass fuel was produced started physical production on or before October 5th 2015',
            sustainabilityCriteriaOfTheBiomassInstallation2:
              'The installation where the final biofuel, bioliquid or biomass fuel was produced started physical production between October 6th 2015 and December 31st 2020',
            sustainabilityCriteriaOfTheBiomassInstallation3:
              'The installation where the final biofuel, bioliquid or biomass fuel was produced started physical production on or after January 1st 2021',

            yes: 'Yes',
            no: 'No',
            ifYesSpecify: 'If yes, please specify:',
            GreenhouseGasInformation: 'Greenhouse Gas (GHG) information',
            GreenhouseGasInformationText: 'Total default value according to RED II applied',
            GreenhouseGasInformationText2: 'Total GHG emissions from supply and use of the fuel',
            dateWhenTheFinalBiofuelProducerStartedOperation: 'Date when the final biofuel producer started operation',
            meanOfTransport: 'Means of transport',
            distance: 'Distance',
            voluntarySchemeCompliantMaterial: 'Voluntary Scheme Compliant Material',
            EUREDCompliantMaterial: 'EU RED II Compliant Material',
            chainOfCustodyOption: 'Chain of custody option',
            europeanWasteCode: 'European Waste Code',
            otherCurrency: 'Other currency',
            currency: 'Currency',
            physicalSegregation: 'Physical segregation',
            massBalance: 'Mass balance',
            countryOfBiofuelProductionPlant: 'Country of biofuel production plant'
          },
          supplierDetails: {
            supplier: 'Supplier'
          },
          collectionSummary: {
            payment: 'Payment',
            collectionSummary: 'Collection summary',
            packaging: 'Packaging',
            paymentForm: 'Payment form',
            paid: 'Paid',
            supplier: 'Supplier (Current Holder)',
            recipient: 'Recipient (Collector)',
            date: 'Date of Issuance of the Proof of Sustainability Declaration'
          },
          schemeCompliantMaterial: {
            na: 'N/A',
            multiple: 'Multiple',
            isccEu: 'ISCC EU',
            rsbEu: 'RSB EU',
            redcertEu: 'REDCert EU',
            bsvs: '2BSVS',
            bonsucro: 'Bonsucro',
            rspo: 'RSPO',
            rtrs: 'RTRS'
          },
          meanOfTransport: {
            shippingContainer: 'Shipping container',
            truck: 'Truck',
            vessel: 'Vessel',
            ship: 'Ship',
            train: 'Train',
            road: 'Road'
          },
          additionalCertificates: {
            INS: 'INS',
            DDC: 'DDC',
            FRDC: 'FRDC',
            DE: 'DE',
            CARB: 'CARB',
            RFS: 'RFS'
          }
        },
        response: {
          success: 'New collection has been successfully created'
        },
        buttonLabels: {
          submit: 'Create Collection'
        },
        errors: {
          fileTooBig: "File size can't exceed 2 MB"
        }
      },
      register: {
        fields: {
          name: 'Company name',
          password: 'Password',
          email: 'E-mail'
        },
        placeholders: {
          name: 'Enter company name',
          email: 'Enter email address',
          password: 'Enter password'
        },
        buttonLabels: {
          submit: 'Create your account'
        },
        response: {
          success: 'Your account has been successfully created'
        }
      },
      login: {
        fields: {
          password: 'Password',
          email: 'E-mail',
          keepMeLoggedIn: 'Keep me logged in'
        },
        placeholders: {
          email: 'Enter email address',
          password: 'Enter password'
        },
        buttonLabels: {
          submit: 'Log in'
        }
      },
      resetPassword: {
        fields: {
          email: 'E-mail'
        },
        placeholders: {
          email: 'Enter email address'
        },
        buttonLabels: {
          submit: 'Reset my password'
        },
        response: {
          success: 'Please check your email to reset your password'
        }
      },
      newPassword: {
        fields: {
          password: 'New password'
        },
        placeholders: {
          password: 'Enter password'
        },
        buttonLabels: {
          submit: 'Submit'
        },
        response: {
          success: 'Your password has been changed successfully!'
        }
      },
      companyAccountSettings: {
        links: {
          settings: 'Settings'
        },
        sections: {
          accountDetails: {
            title: 'Account details'
          },
          organization: {
            title: 'Organizational information',
            description: 'This address will appear on your waste transfer note.'
          },
          staff: {
            title: 'Staff',
            description: 'You can create up to 4 staff accounts.',
            addAdministrator: 'Create administrators account',
            noAccount: 'No account connected'
          },
          logo: {
            title: 'Default logo',
            description: 'This logo will be visible in drivers app. We reccomend using logo on transparent background.'
          },
          certificate: {
            title: 'Certification expiration date'
          },
          sender: {
            title: 'Default sender',
            description: 'This will be displayed in the “From” field of your recipeint’s email client.'
          },
          alerts: {
            title: 'Select event you want to be notified about',
            description: 'Select event you want to be notified about'
          },
          notifications: {
            title: 'Send confirmation to',
            description: 'Collection confirmation and Waste Transfer Note will be sent to this address'
          },
          note: {
            title: 'Collection notes'
          },
          animalByProductStatus: {
            title: 'Animal By Product status',
            description: 'Collection confirmation and Commercial Document will quote this permit.'
          },
          voluntarySchemeCertificate: {
            title: 'Voluntary Scheme Certificate',
            description: 'This address will appear on your waste transfer note.'
          },
          environmentalPermit: {
            title: 'Environmental Permit Number',
            description: 'Collection confirmation and Waste Transfer Note will be sent to this address'
          },
          wasteCarrierLicense: {
            title: 'Waste Carrier License Number',
            description: 'This address will appear on your waste transfer note.'
          },
          collectionTimes: {
            title: 'Collection times',
            days: {
              sunday: 'Sunday',
              monday: 'Monday',
              tuesday: 'Tuesday',
              wednesday: 'Wednesday',
              thursday: 'Thursday',
              friday: 'Friday',
              saturday: 'Saturday'
            }
          },
          informations: {
            unsavedChanges: 'Unsaved changes'
          }
        },
        fields: {
          name: 'Company Name',
          firstName: 'First name',
          lastName: 'Last name',
          phoneNumber: 'Phone number',
          street: 'Street',
          streetNo: 'Apt, suite, etc. (optional)',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          expiryDate: 'Expiry Date',
          senderEmail: 'Sender email',
          emailSenderName: 'Sender email name',
          collectionsAlert: 'Collections made',
          deliveriesAlert: 'Deliveries completed',
          problemsAlert: 'Problems reported',
          reportedSuppliersAlert: 'Proposed suppliers',
          permissions: {
            selectPermissions: 'Select permissions',
            createAuditorAccount: 'Allow to create an read only access account for auditors',
            editDrivers: 'Allow to edit drivers',
            editRoutes: 'Allow to edit routes',
            dateFrom: 'Date from',
            dateTo: 'Date to'
          },
          invitationStatus: 'Pending',
          notificationsEmail: 'Email address',
          note: 'Note',
          animalByProductStatus: {
            permitNumber: 'ABP Permit Number',
            competentAuthority: 'Competent Authority',
            expiryDate: 'Expiry date'
          },
          voluntarySchemeCertificate: {
            permitNumber: 'Voluntary Scheme Certificate Number',
            certifiedOutputs: 'Certified Outputs',
            expiryDate: 'Expiry date'
          },
          environmentalPermitNumber: 'Environmental Permit Number',
          wasteCarrierLicense: {
            permitNumber: 'Waste Carrier License Number',
            expiryDate: 'Expiry date'
          },
          collectionTimeType: {
            any: 'Any',
            periodic: 'Specify suitable time period'
          },
          collectionTimeHours: 'Hours',
          collectionTimeDays: 'Days'
        },
        placeholders: {
          name: 'Company Name',
          firstName: 'First name',
          lastName: 'Last name',
          phoneNumber: 'Phone number',
          street: 'Street',
          streetNo: '',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          expiryDate: 'Select date',
          senderEmail: 'E-mail',
          emailSenderName: 'Sender name',
          permissions: {
            dateFrom: 'Date from',
            dateTo: 'Date to'
          },
          notificationsEmail: 'Email address',
          note: 'Add your note',
          animalByProductStatus: {
            permitNumber: '-',
            competentAuthority: '-',
            expiryDate: 'Select date'
          },
          voluntarySchemeCertificate: {
            permitNumber: '-',
            certifiedOutputs: '-',
            expiryDate: 'Select date'
          },
          environmentalPermitNumber: '-',
          wasteCarrierLicense: {
            permitNumber: '-',
            expiryDate: 'Select date'
          },
          collectionTimeHours: {
            from: 'From',
            to: 'To'
          },
          collectionTimeDays: 'Select days'
        },
        response: {
          success: 'Your account has been successfully updated',
          invitationRemoveSuccess: 'Invitation has been successfully removed',
          adminRemoveSuccess: 'Admin has been successfully removed',
          invitationRemoveError: 'Missing email parameter',
          adminRemoveError: 'Missing admin ID parameter',
          missingEmail: 'Missing email parameter',
          fileSizeError: 'Maximum allowed file size for upload is 2MB'
        },
        popup: {
          removeInvitation: {
            title: 'Are you sure you want to delete this invitation?',
            description:
              'You will keep all the collections made in this location, but you will not be able to add new assignments.',
            buttonLabels: {
              accept: 'Delete invitation',
              decline: 'Cancel'
            }
          },
          removeAdmin: {
            title: 'Are you sure you want to delete this admin?',
            description:
              'You will keep all the collections made in this location, but you will not be able to add new assignments.',
            buttonLabels: {
              accept: 'Delete admin',
              decline: 'Cancel'
            }
          }
        },
        buttonLabels: {
          addStaff: 'Add Staff',
          addImage: 'Add Image',
          save: 'Save',
          cancel: 'Discard'
        }
      },
      inviteAdmin: {
        sections: {
          inviteStaff: {
            title: 'Invite staff',
            description:
              'Invite staff to BioLedger, by sending email invitations. All staff accounts will have full permissions.'
          }
        },
        fields: {
          firstName: 'First name',
          lastName: 'Last name',
          email: 'Email',
          fullAccess: 'Full access',
          readOnlyAccess: 'Read only access',
          limitedAccess: 'Limited access',
          pending: 'Panding',
          permissions: {
            selectPermissions: 'Select permissions',
            createAuditorAccount: 'Allow to create an read only access account for auditors',
            editDrivers: 'Allow to edit drivers',
            editRoutes: 'Allow to edit routes',
            dateFrom: 'Date from',
            dateTo: 'Date to'
          }
        },
        placeholders: {
          firstName: 'First name',
          lastName: 'Last name',
          email: 'Email',
          permissions: {
            dateFrom: 'Date from',
            dateTo: 'Date to'
          }
        },
        response: {
          create: {
            success: 'Your invitation has been successfully sent'
          },
          update: {
            success: 'Permissions has been successfully updated'
          }
        },
        buttonLabels: {
          save: 'Save',
          cancel: 'Discard'
        }
      },
      generalSettings: {
        sections: {
          changePassword: {
            title: 'Password',
            description: 'Change your password'
          },
          deleteAccount: {
            title: 'Delete account',
            description:
              'If you delete your account you will not be able to use Bioledger anymore. You will loose access to all your data'
          }
        },
        popup: {
          deleteAccount: {
            title: 'Are you sure you want to delete your account?',
            description: '',
            buttonLabels: {
              accept: 'Delete account',
              decline: 'Cancel'
            }
          }
        },
        response: {
          deleteAccount: {
            success: 'Your account has been successfully deleted'
          }
        },
        buttonLabels: {
          changePassword: 'Change password',
          deleteAccount: 'Delete account'
        }
      },
      billingSettings: {
        sections: {
          planDetails: {
            title: 'Plan details'
          },
          paymentMethod: {
            title: 'Payment method',
            addPaymentButton: 'Add payment method',
            list: {
              default: 'default',
              expiration: 'Expiration',
              setAsDefaultButton: 'Set as default',
              editButton: 'Edit',
              removeButton: 'Remove'
            },
            form: {
              nameLabel: 'Cardholder full name',
              currencyLabel: 'Currency',
              cardInfoLabel: 'Card info',
              countryLabel: 'Country/Region',
              cardNumberPlaceholder: 'Card number',
              cvvPlaceholder: 'CVV',
              saveButton: 'Save',
              cancelButton: 'Cancel'
            }
          },
          paymentHistory: {
            title: 'Payment history',
            list: {
              receipt: 'Receipt',
              dueDate: 'Due date',
              billingDetailsButton: 'Billing details',
              downloadInvoiceButton: 'Download invoice'
            }
          },
          paymentNotification: {
            first:
              'The last payment failed. We will try to process your payment again over the next few days. Please check your payment method information.',
            second:
              'Our attempts to charge your credit card have failed. Your account will be blocked if the next attempt to charge will not succeed. Please check your payment method information.',
            last:
              'Your account is blocked due to overdue payment(s). To regain access to your account, please contact the system administrator admin@bioledger.io.'
          }
        },
        fields: {
          memberSince: 'Member since',
          currentPlan: 'Current plan',
          status: 'Status'
        },
        buttonLabels: {
          changePlan: 'Change plan',
          changeMethod: 'Change method'
        }
      },
      driverAccount: {
        links: {
          settings: 'Drivers'
        },
        sections: {
          accountDetails: {
            title: 'Driver overview',
            description: 'Driver will regsiter to the app using his phone number'
          },
          address: {
            title: 'Address',
            description: 'This address will appear on your waste transfer note.'
          },
          animalByProductStatus: {
            title: 'Animal By Product status',
            description: 'Collection confirmation and Commercial Document will quote this permit.'
          },
          voluntarySchemeCertificate: {
            title: 'Voluntary Scheme Certificate',
            description: 'This address will appear on your waste transfer note.'
          },
          plateNumbers: {
            title: 'Vehicle registration plate numbers',
            description: 'Plate numbers will help weightbridge operator to verify driver’s identity.'
          },
          wasteCarrierLicense: {
            title: 'Waste Carrier License Number',
            description: 'This address will appear on your waste transfer note.'
          },
          administrator: {
            title: 'Administrator',
            description: 'This contact will be visible in drivers app in help section'
          },
          activate: {
            title: 'Activate',
            description: 'Account has to be active for user to start using the app'
          },
          informations: {
            unsavedChanges: 'Unsaved changes'
          }
        },
        fields: {
          firstName: 'First name',
          lastName: 'Last name',
          phoneNumber: 'Phone number',
          street: 'Street',
          streetNo: 'Apt, suite, etc. (optional)',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          plateNumber: 'Plate number',
          animalByProductStatus: {
            permitNumber: 'ABP Permit Number',
            competentAuthority: 'Competent Authority',
            expiryDate: 'Expiry date'
          },
          voluntarySchemeCertificate: {
            permitNumber: 'Voluntary Scheme Certificate Number',
            certifiedOutputs: 'Certified Outputs',
            validDate: 'Valid date',
            expiryDate: 'Expiry date'
          },
          wasteCarrierLicense: {
            permitNumber: 'Waste Carrier License Number',
            expiryDate: 'Expiry date'
          },
          administrator: 'Administrator',
          isActive: 'Activate this drivers account'
        },
        placeholders: {
          firstName: 'First name',
          lastName: 'Last name',
          phoneNumber: 'Phone number',
          street: 'Street',
          streetNo: '',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          plateNumber: '-',
          animalByProductStatus: {
            permitNumber: '-',
            competentAuthority: '-',
            expiryDate: 'Select date'
          },
          voluntarySchemeCertificate: {
            permitNumber: '-',
            certifiedOutputs: '-',
            validDate: 'Select date',
            expiryDate: 'Select date'
          },
          environmentalPermitNumber: '-',
          wasteCarrierLicense: {
            permitNumber: '-',
            expiryDate: 'Select date'
          },
          administrator: 'Select'
        },
        response: {
          success: 'Account has been successfully created',
          successEdit: 'Account has been successfully updated',
          noAdministrators: 'First you need to add administrators'
        },
        buttonLabels: {
          inviteDriver: 'Invite Driver to download Bioledger App',
          addImage: 'Add Image',
          save: 'Save',
          cancel: 'Discard'
        }
      },
      location: {
        sections: {
          typeOfLocation: {
            title: 'Type of location',
            description: 'Plate numbers will help weightbridge operator to verify driver’s identity.'
          },
          address: {
            title: 'Address',
            description: 'This address will appear on your waste transfer note.'
          },
          manager: {
            title: 'Manager',
            description: 'Add contact information'
          },
          notifications: {
            title: 'Send confirmation to',
            description: 'Collection confirmation and Waste Transfer Note will be sent to this address'
          },
          note: {
            title: 'Collection notes'
          },
          animalByProductStatus: {
            title: 'Animal By Product status',
            description: 'Collection confirmation and Commercial Document will quote this permit.'
          },
          voluntarySchemeCertificate: {
            title: 'Voluntary Scheme Certificate',
            description: 'This address will appear on your waste transfer note.'
          },
          environmentalPermit: {
            title: 'Environmental Permit Number',
            description: 'Collection confirmation and Waste Transfer Note will be sent to this address'
          },
          wasteCarrierLicense: {
            title: 'Waste Carrier License Number',
            description: 'This address will appear on your waste transfer note.'
          },
          collectionQuantity: {
            title: 'Typical collection quantity'
          },
          collectionFrequency: {
            title: 'Typical collection frequency'
          },
          packaging: {
            title: 'Packaging',
            description: 'Add information about the packaging used by this location.'
          },
          paymentTerms: {
            title: 'Payment terms'
          },
          certificationsAndPermissions: {
            title: 'Certifications and Permissions'
          },
          collectionTimes: {
            title: 'Collection times',
            days: {
              sunday: 'Sunday',
              monday: 'Monday',
              tuesday: 'Tuesday',
              wednesday: 'Wednesday',
              thursday: 'Thursday',
              friday: 'Friday',
              saturday: 'Saturday'
            }
          },
          informations: {
            unsavedChanges: 'Unsaved changes'
          }
        },
        fields: {
          locationType: {
            pickUp: 'Collection',
            depot: 'Delivery',
            customer: 'Customer'
          },
          locationName: 'Location name',
          street: 'Street',
          streetNo: 'Apt, suite, etc. (optional)',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          verifiedUser: 'A verified Bioledger user',
          manager: {
            name: 'Name',
            phoneNumber: 'Phone number',
            addAnother: 'Add another'
          },
          notifications: {
            email: 'Email address',
            type: {
              title: 'Forward Collection or Sales documents',
              options: {
                manual: 'Manually',
                onExecution: 'Upon execution'
              }
            }
          },
          note: 'Note',
          animalByProductStatus: {
            permitNumber: 'Number',
            competentAuthority: 'Competent Authority',
            expiryDate: 'Expiry date'
          },
          voluntarySchemeCertificate: {
            permitNumber: 'Number',
            certifiedOutputs: 'Certified Outputs',
            expiryDate: 'Expiry date'
          },
          environmentalPermitNumber: 'Number',
          wasteCarrierLicense: {
            permitNumber: 'Number',
            expiryDate: 'Expiry date'
          },
          collectionTimeType: {
            any: 'Any',
            periodic: 'Specify suitable time period'
          },
          collectionTimeHours: 'Hours',
          collectionTimeDays: 'Days',
          collectionQuantity: 'Quantity',
          collectionQuantityMeasure: {
            litres: 'Liters',
            kilograms: 'Kilograms'
          },
          collectionFrequencyPeriod: {
            daily: 'Daily',
            weekly: 'Weekly',
            biWeekly: 'Bi-weekly',
            other: 'Other'
          },
          packagingTypes: {
            bottlesAndTins: 'Bottles/Tins',
            barrel60L: '60 L Barrel',
            barrel120L: '120 L Barrel',
            barrel150L: '150 L Barrel',
            barrel200L: '200 L Barrel',
            wheelieBin: 'Wheelie Bin',
            ibc: 'IBC',
            other: 'Other'
          },
          paymentTerms: {
            cash: 'Cash',
            account: 'Account',
            iou: 'IOU'
          }
        },
        placeholders: {
          locationName: 'Location name',
          street: 'Street',
          streetNo: '',
          city: 'City',
          zipCode: 'Postal / ZIP code',
          country: 'Country',
          manager: {
            name: 'Name',
            phoneNumber: 'Phone number'
          },
          notifications: {
            email: 'Email address'
          },
          note: 'Add your note',
          animalByProductStatus: {
            permitNumber: '-',
            competentAuthority: '-',
            expiryDate: 'Select date'
          },
          voluntarySchemeCertificate: {
            permitNumber: '-',
            certifiedOutputs: '-',
            expiryDate: 'Select date'
          },
          environmentalPermitNumber: '-',
          wasteCarrierLicense: {
            permitNumber: '-',
            expiryDate: 'Select date'
          },
          collectionTimeHours: {
            from: 'From',
            to: 'To'
          },
          collectionTimeDays: 'Select days',
          collectionQuantity: 'Quantity',
          certificationTypes: 'Choose certification types'
        },
        informations: {
          unsavedChanges: 'Unsaved changes'
        },
        response: {
          success: 'Location has been successfully created',
          successEdit: 'Location has been successfully updated'
        },
        buttonLabels: {
          save: 'Save',
          cancel: 'Discard'
        }
      },
      createRoute: {
        fields: {
          name: 'Route name',
          depot: 'Depot'
        },
        placeholders: {
          name: 'Name',
          locations: 'Search location',
          depot: 'Search depot'
        },
        buttonLabels: {
          cancel: 'Cancel',
          save: 'Save'
        },
        response: {
          success: 'Route has been successfully created',
          successEdit: 'Route has been successfully updated'
        }
      },
      assignment: {
        sections: {
          name: {
            title: 'Assignment ID',
            description: 'Add ID number for this assignment'
          },
          driver: {
            title: 'Driver',
            description: 'Select active driver'
          },
          date: {
            title: 'Date'
          },
          route: {
            title: 'Route',
            description: 'After you select route, you can customize it - edit it or add notes for the driver',
            changeRoute: 'Change route'
          },
          activateAssignment: {
            title: 'Activate assignment'
          },
          informations: {
            unsavedChanges: 'Unsaved changes'
          }
        },
        fields: {
          driver: 'Driver',
          schedule: {
            selectDate: 'Select date',
            selectTime: 'Select time',
            period: {
              days: 'DAYS',
              weeks: 'WEEKS',
              months: 'MONTHS'
            }
          },
          recurringAssignment: 'Recurring assignment',
          repeatEvery: 'Repeat every',
          route: 'Route',
          activeAssignment: 'Active assignment'
        },
        placeholders: {
          name: 'e.g. 1234',
          driver: 'Search driver',
          route: 'Search route',
          schedule: {
            selectDate: 'Select date',
            selectTime: 'Select time'
          }
        },
        buttonLabels: {
          save: 'Save',
          cancel: 'Discard'
        },
        response: {
          success: 'Assignment has been successfully created',
          successEdit: 'Assignment has been successfully updated'
        }
      },
      adjustStock: {
        warning: {
          title: 'This item includes sales and cannot be modified'
        },
        response: {
          successEdit: 'Stock has been successfully updated'
        }
      },
      sales: {
        sections: {
          details: {
            salesSummary: 'Sales summary',
            psychicalQuantity: 'Physical quantity',
            allocatedQuantity: 'Allocated quantity',
            leftInStock: 'Left in stock',
            saleDetails: 'Sale details',
            saleId: 'ID',
            orderDetails: 'Order details',
            customer: 'Customer',
            orderCompleted: 'Order Completed',
            productDetails: 'Product details',
            transformCollection: 'Transform collection',
            collectionLink: 'Collections',
            additionalData: 'Additional Data'
          },
          customer: {
            customerDescription:
              'If you select a verified Bioledger user as a customer, you will have to wait for their acceptance to finalise the transaction. ',
            verifiedUserHint: '–  A verified Bioledger user. Lorem Ipsum dolor sin amet.'
          }
        },
        buttonLabels: {
          submit: 'Submit sales order',
          back: 'Back',
          next: 'Next',
          submitTransform: 'Transform'
        },
        placeholders: {
          customer: 'Search for a customer'
        },
        fields: {
          salesIdNumber: 'Sales ID Number',
          defineSalesIdNumber: 'Define Collection ID number',
          totalQuantity: 'Sales Total Quantity',
          dateOfDispatch: 'Date of dispatch',
          sellersVoluntarySchemeCertificate: 'Sellers Voluntary Scheme Certificate',
          meanOfTransport: 'Mean of transport',
          chainOfCustodyOption: 'Chain of custody option',
          distance: 'Distance',
          note: 'Note',
          feedstockType: 'Raw material types',
          sameAddress: 'Same as address of supplier',
          placeOfDispatch: 'Place of dispatch',
          productType: 'Product type',
          documentsLabel: 'Documents',
          documentUpload: 'Upload a document',
          transformConsignment: 'Transform product',
          transformConsignmentDescription: 'Will your Customer be transforming this product?',
          transformYesHint: 'Please select a product type:',
          transformProductType: 'Product type'
        },
        errors: {
          maxQuantity: 'Allocated total exceeds total sales quantity',
          collectionLoading: 'Something went wrong'
        },
        messages: {
          newSale: 'New sale has been successfully created'
        },
        details: {
          summary: 'Sale summary'
        }
      },
      order: {
        sections: {
          orderSummary: {
            customer: 'Customer',
            orderSummary: 'Order summary'
          },
          details: {
            collectionDate: 'Collection date',
            quantity: 'Quantity',
            rawMaterialTypes: 'Raw material types',
            productDetails: 'Product details',
            sameAddress: 'Same as address of supplier',
            sameAddressOfReceipt: 'Same as address of recipient',
            placeOfDispatch: 'Place of dispatch',
            physicalQuantity: 'Physical quantity',
            allocatedQuantity: 'Allocated quantity',
            dateOfDispatch: 'Date of dispatch',
            physicalQuantityLoaded: 'Physical quantity loaded',
            orderIdNumber: 'Sales ID number',
            sellersVolountarySchemeCertificate: 'Sellers Voluntary Scheme Certificate',
            orderDetails: 'Order details',
            customer: 'Customer',
            orderCompleted: 'Order completed',
            note: 'Note',
            documents: 'Documents',
            orderSummary: 'Order summary',
            link: 'Sales',
            environmentalPermitNumber: 'Environmental Permit Number',
            wasteCarrierLicenseNumber: 'Waste Carrier License Number',
            manager: 'Manager',
            address: 'Address',
            vsc: 'Voluntary Scheme Certificate',
            vscExpiryDate: 'Expiry date:',
            abpPermitNumber: 'Abp Permit Number',
            abpCompetentAuthority: 'Competent Authority:',
            abpExpiryDate: 'Expiry date:',
            seller: 'Seller',
            email: 'E-mail',
            phone: 'Phone number',
            balanceSummary: 'Balance summary',
            totalBalance: 'Total balance',
            acceptCollectionQuestion: 'Do you want to accept this collection?',
            totalPhysicalQuantity: 'Total physical quantity',
            meanOfTransport: 'Mean of transport',
            chainOfCustodyOption: 'Chain of custody option',
            distance: 'Distance',
            productType: 'Product type',
            totalAllocated: 'Total allocated',
            leftInStock: 'Left in stock',
            salesDetails: 'Sales details',
            energyContent: 'Energy content',
            certificationStatus: 'Certification status',
            additionalCertification: 'Additional certification (optional)',
            voluntarySchemeCompliantMaterial: 'Voluntary Scheme Compliant Material',
            EUREDCompliantMaterial: 'Total default value according to RED II applied',
            ghgEmissions: 'Total GHG emissions from supply and use of the fuel',
            countryOfOrigin: 'Country of origin',
            feedstockBalance: 'Balance',
            sustainabilityCriteria:
              'The raw material complies with the sustainability criteria according to Art.17 (3), (4), (5) RED1',
            sustainabilityCriteria2: 'Raw material meets the definition of waste or residue according to the RED II',
            europeanWasteCode: 'European Waste Code',
            dateWhenTheFinalBiofuelProducerStartedOperation: 'Date when the final biofuel producer started operation',
            placeOfReceipt: 'Place of receipt of the sustainable material',
            yes: 'Yes',
            no: 'No'
          }
        },
        fields: {
          salesIdNumber: 'Sales ID Number',
          totalQuantity: 'Sales Total Quantity',
          totalQuantityMeasure: {
            litres: 'Litres',
            kilograms: 'Kilograms'
          },
          salesTotalQuantity: 'Choose feedstock for sale',
          feedstockType: 'Choose feedstock for sale',
          certificationStatus: 'Certification Status',
          volume: 'Volume',
          note: 'Note',
          documents: 'Documents',
          collectionDate: 'Date of dispatch',
          period: 'Date range'
        },
        placeholders: {
          salesIdNumber: '',
          totalQuantity: '',
          certificationStatus: '',
          volume: '',
          collectionDate: '',
          period: '',
          customer: ''
        },
        labels: {
          all: 'All',
          certified: 'Red Compliant',
          uncertified: 'Uncertified',
          bioledger: 'Bioledger',
          redCompliant: 'Red Compliant',
          verifiedCustomer:
            'If you select a verified Bioledger user, the transaction won’t be finalized until the customer accepts the order.'
        },
        errors: {
          valueExceeded: 'Allocated total exceeds total sales quantity'
        },
        buttonLabels: {
          submit: 'Submit sales order'
        },
        response: {
          success: 'Order has been successfully created',
          customerNotFound: 'No available stock or customers found'
        }
      },
      commonFields: {
        optional: '(optional)'
      },
      errors: {
        required: 'This field is required',
        minLength: 'Value is too short',
        maxLength: 'Value is too long',
        passwordsMatch: 'Values do not match',
        mustBeAValidEmail: 'Must be a valid email',
        greaterThanZero: 'Value must be greater than 0',
        max100percent: 'Percentage values should sum up to 100%'
      },
      firebaseErrors: {
        tooShort: 'The phone number cannot start with a zero and should be at least 10 digits long'
      }
    },
    table: {
      paymentsTable: {
        headers: {
          transactionId: 'Stripe transaction ID',
          companyName: 'Company Name',
          date: 'Date',
          amount: 'Amount'
        },
        tabs: {
          success: 'Received',
          failed: 'Overdue'
        }
      },
      driversTable: {
        headers: {
          name: 'Name',
          phone: 'Phone',
          lastLogged: 'Last logged',
          status: 'Status',
          assignmentsCompleted: 'Assignments Compl.'
        },
        availableStauses: {
          active: 'Active',
          inactive: 'Inactive'
        },
        modal: {
          title: 'Are you sure you want to delete selected items?',
          description: '',
          confirmation: 'Delete driver'
        },
        warnings: {
          missingFile: 'Please select a file'
        },
        response: {
          success: 'Drivers has been successfully deleted',
          exportSuccess: 'Successfully added {{noOfAddedDocs}} of {{docsToAdd}} documents'
        }
      },
      locationsTable: {
        headers: {
          name: 'Name',
          street: 'Street',
          phone: 'Phone',
          route: 'Route',
          frequency: 'Frequency'
        },
        tabs: {
          all: 'All',
          pickupPoints: 'Pick-up points',
          depots: 'Depots',
          customer: 'Customers'
        },
        modal: {
          title: 'Are you sure you want to delete this location?',
          description:
            'You will keep all the collections made in this location, but you will not be able to add new assignments.',
          confirmation: 'Delete location'
        },
        warnings: {
          missingFile: 'Please select a file'
        },
        response: {
          success: 'Locations has been successfully deleted',
          exportSuccess: 'Successfully added {{noOfAddedDocs}} of {{docsToAdd}} documents'
        }
      },
      assignmentsTable: {
        headers: {
          name: 'Assignment ID',
          driver: 'Driver',
          plannedDate: 'Planned date',
          type: 'Type',
          status: 'Status'
        },
        tabs: {
          all: 'All',
          completed: 'Completed',
          ongoing: 'Ongoing',
          scheduled: 'Scheduled',
          overdue: 'Incomplete'
        },
        types: {
          oneTime: 'One-time',
          recurring: 'Recurring'
        },
        statusType: {
          active: 'Active',
          inactive: 'Inactive'
        },
        modal: {
          title: 'Are you sure you want to delete this assignment?',
          confirmation: 'Delete assignment'
        },
        warnings: {
          missingFile: 'Please select a file'
        },
        response: {
          success: 'Assignments has been successfully deleted',
          exportSuccess: 'Successfully added {{noOfAddedDocs}} of {{docsToAdd}} documents'
        }
      },
      routesTable: {
        headers: {
          name: 'Name',
          waypoints: 'Waypoints',
          recuringEvery: 'Recurring every',
          lastCollection: 'Last collection',
          lastQty: 'Last Qty',
          lastCollectionDriver: 'Last collection driver',
          depot: 'Depot'
        },
        warnings: {
          missingFile: 'Please select a file'
        },
        modal: {
          title: 'Are you sure you want to delete this route?',
          confirmation: 'Delete route'
        },
        response: {
          success: 'Routes has been successfully deleted',
          exportSuccess: 'Successfully added {{noOfAddedDocs}} of {{docsToAdd}} documents'
        }
      },
      assignmentSummaryTable: {
        headers: {
          status: 'Status',
          collection: 'Collection',
          location: 'Location',
          payment: 'Payment',
          paymentMethod: 'Method',
          feedstock: 'Feedstock',
          weight: 'Weight/Volume'
        }
      },
      collectionsTable: {
        tabs: {
          myCollections: 'My collections',
          proposedCollections: 'Proposed collections'
        },
        headers: {
          collection: 'Collection ID',
          date: 'Date',
          supplierName: 'Supplier name',
          route: 'Route',
          driverAdminName: 'Driver / Admin',
          rawMaterial: 'Raw Material',
          certification: 'Certification',
          additionalCertification: 'Additional certification',
          quantity: 'Quantity',
          seller: 'Seller',
          saleId: 'Sale ID'
        },
        measures: {
          kilograms: 'KG',
          litres: 'L'
        },
        status: {
          skipped: 'skipped',
          problemReported: 'problem reported',
          incomplete: 'incomplete',
          complete: 'complete'
        }
      },
      stockTable: {
        headers: {
          product: 'Product',
          rawMaterial: 'Raw material',
          certification: 'Certification',
          countryOfOrigin: 'Country of origin',
          deliveryDate: 'Delivery date',
          supplier: 'Supplier',
          carbonIntensity: 'Carbon intensity (gCo2eq)',
          quantity: 'Quantity (kg)',
          id: 'Id',
          collectionId: 'Collection ID',
          feedstock: 'Feedstock',
          certificationType: 'Certification Type',
          collectedFrom: 'Collected from',
          volume: 'Volume',
          adjustManually: 'Adjust manually',
          applyYieldLoss: 'Apply yield loss'
        },
        tabs: {
          all: 'All',
          ucoVeg: 'UCO Veg',
          ucoVegAnimal: 'UCO Veg + Animal',
          foodWaste: 'Food Waste',
          brownGrease: 'Brown Grease'
        },
        certificate: {
          certified: 'Certified',
          uncertified: 'Uncertified'
        },
        adjustment: {
          adjusted: 'Adjusted',
          notAdjusted: 'Not adjusted'
        }
      },
      ordersTable: {
        headers: {
          orderId: 'Sales ID number',
          saleId: 'Sales ID number',
          date: 'Date',
          customer: 'Customer',
          volume: 'Volume',
          feedstock: 'Feedstock',
          quantityMeasure: 'Qty measure',
          status: 'Status'
        }
      },
      clientsTable: {
        headers: {
          companyName: 'Company Name',
          noOfUsers: 'Number of users',
          volumeTransfered: 'Volume transfered (current month)',
          status: 'Status'
        },
        statusType: {
          active: 'Active',
          locked: 'Locked'
        }
      },
      common: {
        selectedItems: 'Selected',
        import: 'Import',
        export: 'Export',
        assignmentStatuses: {
          completed: 'COMPLETED',
          scheduled: 'SCHEDULED',
          ongoing: 'ONGOING',
          overdue: 'INCOMPLETE'
        }
      },
      buttonLabels: {
        accept: 'Accept',
        delete: 'Delete',
        cancel: 'Cancel'
      }
    },
    common: {
      assignmentStatuses: {
        completed: 'COMPLETED',
        scheduled: 'SCHEDULED',
        ongoing: 'ONGOING',
        overdue: 'INCOMPLETE',
        problemReported: 'PROBLEM REPORTED',
        skipped: 'SKIPPED'
      },
      availableProductTypes: {
        biodiesel: 'Biodiesel',
        bioethanol: 'Bioethanol',
        biogas: 'Biogas / Biomethane',
        biomethanol: 'Biomethanol',
        hvo: 'HVO - hydrotreated vegetable oil',
        pureVegetableOil: 'Pure vegetable oil',
        rawMaterial: 'Raw material',
        bioButane: 'Biobutane',
        bioButanol: 'Biobutanol',
        bioButene: 'Biobutene',
        bioDiesel: 'Biodiesel',
        bioThanol: 'Bioethanol',
        bioGas: 'Biogas',
        bioGasoline: 'Biogasoline',
        bioLNG: 'Bio-LNG',
        bioLPG: 'Bio-LPG',
        bioMethane: 'Biomethane ',
        bioMethanol: 'Biomethanol',
        bioNaphta: 'Bionaphta',
        bioPropane: 'Biopropane',
        bioPropanol: 'Biopropanol',
        bioMarineFuel: 'Bio Marine Fuel',
        coProcessedOilToBeUsedAsAReplacementOfDiesel: 'Co-processed oil to be used as a replacement of diesel',
        coProcessedOilToBeUsedAsAReplacementOfPetrol: 'Co-processed oil to be used as a replacement of petrol',
        coProcessedOilToBeUsedAsAReplacementOfLiquefiedPetrolumGas:
          'Co-processed oil to be used as a replacement of liquefied petroleum gas',
        coProcessedOilToBeUsedAsAReplacementOfNaphta: 'Co-processed oil to be used as a replacement of naphta',
        coProcessedOilToBeUsedAsAReplacementOfPetrolDeiselJetProducedFromBiomethane:
          'Co-processed oil to be used as a replacement of petrol/diesel/jet produced from biomethane',
        cornOil: 'Corn oil ',
        crudeOil: 'Crude oil ',
        bioETBE: 'Bio-ETBE (the part from renewable sources)',
        esterifiedFattyAcids: 'Esterified fatty acids',
        fattyAcids: 'Fatty acids',
        heatSteam: 'Heat /Steam',
        HEFA: 'HEFA',
        HVO: 'HVO',
        flourMeal: 'Flour / Meal',
        Karitene: 'Karitene',
        liquidDestrose: 'Liquid destrose (LDX)',
        Molasses: 'Molasses',
        Olein: 'Olein',
        palmKernel: 'Palm kernel',
        plamKernemOil: 'Palm kermel oil (PKO)',
        pellets: 'Pellets',
        pulp: 'Pulp',
        refinedAnimalFatTallow: 'Refined animal fat / tallow (specification of category)',
        refinedGlycerine: 'Refined glycerine',
        refinedOil: 'Refined oil',
        renewableDeisel: 'Renewable diesel',
        sheaButter: 'Shea butter',
        sheaMeal: 'Shea meal',
        spentBleachingEarthOil: 'Spent bleaching earth oil',
        starchSlurry: 'Starch slurry',
        stearin: 'Stearin',
        sugarcaneJuice: 'Sugar cane juice',
        taee: 'TAEE (the part from renewable sources)',
        tallOilResin: 'Tall oil resin',
        tame: 'TAME (the part from renewable sources)',
        thickJuice: 'Thick juice'
      },
      availableFeedstocks: {
        ucoVeg: 'UCO Veg',
        ucoVegAnimal: 'UCO Veg + Animal',
        foodWaste: 'Food Waste',
        brownGrease: 'Brown Grease',
        animalFatTallowCategory1: 'Animal fat / tallow (category 1)',
        animalFatTallowCategory2: 'Animal fat / tallow (category 2)',
        animalFatTallowCategory3: 'Animal fat / tallow (category 3)',
        animalFatTallowUncategorised: 'Animal fat / tallow (uncategorised) ',
        bagasse: 'Bagasse',
        barley: 'Barley',
        brownGrease_GeaseTrapFat: 'Brown grease / grease trap fat',
        browLiquorSpentSulphiteLiquor: 'Brown liquor / spent sulphite liquor',
        cameline: 'Camelina',
        cashewNulShellLiquidCNSL: 'Cashew Nut Shell Liquid (CNSL)',
        cornMaize: 'Corn / Maize',
        cornMaizeCobs: 'Corn / Maize cobs',
        cotton: 'Cotton',
        cottonSeed: 'Cotton seed',
        crudeGlycerine: 'Crude glycerine',
        crudeTallOilCTO: 'Crude tall oil (CTO)',
        draff: 'Draff',
        emptyplamFriutBunchesEFBOil: 'EmptyPalm Fruit Bunches (EFB) oil',
        ethanolUsedInTheCleaningExtractionOfBloodPlasma: 'Ethanol used in the cleaning/extraction of blood plasma',
        ethanolUsedInTheExtractionOfIngredientsFromMedicinalPlants:
          'Ethanol used in the extraction of ingredients from medicinal plants',
        fishOilEthylEsterFoee: 'Fish oil Ethyl Ester (FOEE)',
        flowerBulbs: 'Flower bulbs',
        // foodWaste: 'Food waste',
        forestryResidues: 'Forestry residues',
        forestryProcessingResidues: 'Forestry processing residues',
        fruitTreeCuttings: 'Fruit tree cuttings (from agriculture)',
        giantCane: 'Giant cane',
        grapeMarc: 'Grape marc',
        grass: 'Grass',
        husks: 'Husks',
        jatropha: 'Jatropha',
        manure: 'Manure',
        mustardCarinate: 'Mustard/Carinata',
        nutShells: 'Nut shells (specification of nut)',
        oat: 'Oat',
        oilPlamFreshFruitFFBS: 'Oil palm fresh fruit (FFBs)',
        olives: 'Olives',
        organicMunicipalSolidWasteMSW: 'Organic municipal solid waste (MSW)',
        palmFattyAcidDistillatePFAD: 'Palm Fatty Acid Distillate (PFAD)',
        plamKernelShellsPKS: 'Palm kernel shells (PKS)',
        plamOilMillEffluentPomeOil: 'Palm oil mill effluent (POME) oil',
        potAle: 'Pot ale',
        poaltryFeatherAcidOil: 'Poultry feather acid oil',
        rapeseedCanola: 'Rapeseed / canola',
        renewableComponentOfEndOfLifeTyres: 'Renewable component of end-of-life tyres',
        residuesFromTheProcessingOfCornMaize: 'Residues from the processing of corn/maize',
        municipleGrassCuttings: 'Municipal grass cuttings',
        sewageSludge: 'Sewage sludge',
        sewageSystemFog: 'Sewage system FOG',
        sheaNuts: 'Shea nuts',
        shortRotationCoppice: 'Short Rotation Coppice',
        sorghum: 'Sorghum',
        soybean: 'Soybean',
        spentBleachingEarth: 'Spent bleaching earth',
        starchSlurryLowGrade: 'Starch slurry (low grade)',
        strawSpecificationOfCrop: 'Straw (specification of crop)',
        sugarBeet: 'Sugar beet',
        sugarBeetResidues: 'Sugar beet residues',
        sugarBeetBetaineResidue: 'Sugar beet betaine residue',
        sugarCane: 'Sugar cane',
        sunFlower: 'Sunflower',
        tallOilPitch: 'Tall oil pitch',
        technicalCornOil: 'Technical corn oil',
        tigerNutsChuffa: 'Tiger nuts / Chuffa',
        trasesterificationResuduesTer: 'Transesterification residues (TER)',
        triticale: 'Triticale',
        usedCookingOilUCOEntirelyOgVegOrigin: 'Used cooking oil (UCO) entirely of veg. origin',
        usedCookingOilEntirelyOrPartlyOfAnimalOrigin: 'Used cooking oil (UCO) entirely or partly of animal origin',
        wastePressingFromProductionOfVegitableOils: 'Waste pressings (from production of vegetable oils)',
        wasteResiduesFromProcessingOfAlcohol: 'Waste/residues from processing of alcohol',
        wasteResiduesFromProcessingOfVegetableOrAnimalOil:
          'Waste/residues from processing of vegetable or animal oil (specification of raw material or crop)',
        wasteStarchSlurry: 'Waste starch slurry',
        wasteSlurryFromTheDistillationOfGrainMixtures: 'Waste slurry from the distillation of grain mixtures',
        wasteOilFromSewageSludgeTreatment: 'Waste oil from sewage sludge treatment',
        wasteWood: 'Waste wood',
        Wheat: 'Wheat ',
        wheyPermeate: 'Whey permeate',
        wineLees: 'Wine lees',
        woodyBiomassFractionOfNonRecyclableIndustrialAndMunicapalConstructionAndDemolitionWaste:
          'Woody biomass fraction of non-recyclable industrial and municipal construction and demolition waste'
      },
      packaging: {
        bottlesAndTins: 'Bottles and tins',
        barrel60L: '60L Barrel',
        barrel120L: '120L Barrel',
        barrel150L: '150L Barrel',
        barrel200L: '200L Barrel',
        wheelieBin: 'Wheelie Bin',
        ibc: 'IBC',
        other: 'Other'
      },
      locationProblem: {
        premisesClosed: 'Premises Closed',
        noAccess: 'No access',
        containerEmpty: 'Empty container',
        paringIssue: 'Parking issue',
        other: 'Other'
      },
      depotProblem: {
        noAccess: 'No access',
        oilStolen: 'Oil stolen',
        vehicleIssue: 'Vehicle isssue',
        paringIssue: 'Parking issue',
        other: 'Other'
      },
      certificate: {
        certified: 'Certified',
        uncertified: 'Uncertified'
      },
      access: {
        readOnly: 'You are not authorized to this resource'
      }
    }
  }
};

export default en;
