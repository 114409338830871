import { createStyles } from '@material-ui/core/styles';
import { white } from '@theme/variables';

export default createStyles({
  notification: {
    position: 'relative',
    top: 0,
    right: 0,
    marginBottom: 10,
    zIndex: 1400,
    '& .MuiAlert-message': {
      color: white
    }
  }
});
