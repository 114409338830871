import i18n from '@translations/i18n/i18n';
import React, { useState } from 'react';
import { I18nextProvider } from 'react-i18next';

import { LocaleContext } from './locale.context';
import { ILocaleProviderProps } from './locale.types';

export const LocaleProvider = ({ children }: ILocaleProviderProps) => {
  const [locale, setLocale] = useState('en');

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
