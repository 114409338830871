import React, { useCallback, useState } from 'react';

import { NotificationContext } from './notification.context';
import { INotificationProviderProps } from './notification.types';
import { NotificationContainer } from './notificationContainer/notificationContainer';

let id = 0;

export const NotificationProvider = ({ children }: INotificationProviderProps) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback(
    (content, type, isOpen) => {
      setNotifications((notifications) => [...notifications, { id: id++, content, type, isOpen }]);
    },
    [setNotifications]
  );

  const removeNotification = useCallback(
    (id) => {
      setNotifications((notifications) => notifications.filter((t) => t.id !== id));
    },
    [setNotifications]
  );

  return (
    <NotificationContext.Provider value={{ addNotification, removeNotification }}>
      <NotificationContainer notifications={notifications} />
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
