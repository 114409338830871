import '../global.css';

import { AuthProvider } from '@auth/auth.provider';
import { LocaleProvider } from '@locale/locale.provider';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { NotificationProvider } from '@notification/notification.provider';
import Routes from '@routes/routes.loadable';
import { theme } from '@theme/theme';
import React, { FunctionComponent } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const App: FunctionComponent = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <LocaleProvider>
      <NotificationProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </NotificationProvider>
    </LocaleProvider>
  </ThemeProvider>
);
