import { Loader } from '@components/loader/loader';
import React, { useEffect, useState } from 'react';

import { AuthContext } from './auth.context';
import { IAuthProviderProps } from './auth.types';
import {
  confirmPasswordReset,
  doPasswordReset,
  doSignInWithCustomToken,
  doSignInWithEmailAndPassword,
  doSignOut,
  onAuthStateChange,
  verifyPasswordResetCode
} from './auth.utils';

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState({ loggedIn: false, isBlocked: false });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser, setLoading);
    return () => {
      unsubscribe();
    };
  }, []);

  if (loading) return <Loader />;

  return (
    <AuthContext.Provider
      value={{
        user,
        doSignOut,
        doSignInWithEmailAndPassword,
        doSignInWithCustomToken,
        doPasswordReset,
        verifyPasswordResetCode,
        confirmPasswordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
