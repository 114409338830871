import { createContext } from 'react';

import { INotificationContext } from './notification.types';

export const NotificationContext = createContext<INotificationContext>({
  addNotification: null,
  removeNotification: null
});

NotificationContext.displayName = 'NotificationContext';
